body {
  margin: 0;
  overflow: hidden;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.title-font{
  font-family: 'Playfair Display SC';
}

.shadow-border{
  box-sizing: border-box;
  padding: 1em;
  background-image: url("./img/shadow-box.png");
  background-size: 99.5% 99.5%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-color: transparent;
  /*border-image: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(127,128,132,1) 30%, rgba(197,196,202,1) 50%, rgba(127,128,132,1) 80%, rgba(255,255,255,1) 100%);*/
}

.shadow-box{
  border: 5px solid gray;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, .58);
}

/**
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
 */

.container{
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  padding: 0 !important;
  max-width: 100vw !important;
  position: relative;
  left: 0;
  right: 0;
  overflow-y: auto;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.main{
  width: 100%;
  height: calc(100% - 110px);
  display: flex;
}

a{
  text-decoration: none;
}

.container{
  overflow-y: visible !important;
}

.router-link{
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow{
  box-sizing: border-box;
  padding: 3rem; /* Arrow size - будем переопределять наследником */
  box-shadow: 1px -1px 0 1px #c2c2c2 inset;
  -webkit-box-shadow: 2px -2px #c2c2c2 inset;
  border: solid transparent;
  transition: 0.2s
}

.arrow:hover {
  box-shadow: 2px -2px 0 2px #fff inset;
  -webkit-box-shadow: 4px -4px #fff inset;
}

.arrow-up    { transform:rotate(135deg) }
.arrow-right { transform:rotate(225deg) }
.arrow-down  { transform:rotate(-45deg) }
.arrow-left  { transform:rotate(45deg) }